/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Flex, reusables } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { HeaderActions, StyledHamburger } from '~/app/components/HeaderActions';
import { Can, Flags, Icon } from '~/shared/components';

import peopleTechLogo from '~/../public/img/people_tech_logo.png';

import { NavLink } from '../NavLink';

import { StyledNav, StyledNavFooter } from './styles';

type NavContentProps = {
  isCollapsed?: boolean;
  shouldShowHeader?: boolean;
  onToggleRequest: () => void;
  onOpenRequest: () => void;
};

export const NavContent = React.forwardRef<
  React.ElementRef<typeof StyledHamburger>,
  NavContentProps
>(({ isCollapsed, shouldShowHeader, onToggleRequest }, forwardedRef) => {
  const { t } = useTranslate('default');
  const { asPath } = useRouter();
  return (
    <>
      {shouldShowHeader && (
        <HeaderActions
          isDetached
          isCollapsed={isCollapsed}
          onToggleRequest={onToggleRequest}
          ref={forwardedRef}
        />
      )}

      <StyledNav collapsed={isCollapsed} className={reusables.scrollbar()}>
        <Flex direction="column" css={{ gap: '$5' }}>
          <Flags authorizedFlags="home">
            <Can I="view" a="Home">
              {() => (
                <NavLink
                  href="/home"
                  icon={
                    <Icon.Home color={asPath === '/home' ? 'white' : 'black'} />
                  }
                  title={t('menu.home')}
                  collapsed={isCollapsed}
                />
              )}
            </Can>
          </Flags>
          <Flags authorizedFlags="surveys">
            <Can I="view" a="Surveys">
              {() => (
                <NavLink
                  href="/surveys/list"
                  icon={
                    <Icon.SurveyListMenu
                      color={asPath === '/surveys/list' ? 'white' : 'black'}
                    />
                  }
                  title={t('menu.survey_list')}
                  collapsed={isCollapsed}
                />
              )}
            </Can>
          </Flags>
          <Flags authorizedFlags="surveys">
            <Can I="view" a="Surveys">
              {() => (
                <NavLink
                  href="/surveys/create"
                  icon={
                    <Icon.SurveyCreateMenu
                      color={asPath === '/surveys/create' ? 'white' : 'black'}
                    />
                  }
                  title={t('menu.survey_create')}
                  collapsed={isCollapsed}
                />
              )}
            </Can>
          </Flags>
          <Flags authorizedFlags="documents">
            <Can I="view" a="Surveys">
              {() => (
                <NavLink
                  href="/download-documents"
                  icon={
                    <Icon.Download
                      color={
                        asPath === '/download-documents' ? 'white' : 'black'
                      }
                    />
                  }
                  title={t('menu.download_documents')}
                  collapsed={isCollapsed}
                />
              )}
            </Can>
          </Flags>
        </Flex>

        <StyledNavFooter collapsed={isCollapsed}>
          <hr />

          {/* TODO: Replace below URL with 180/360 Knowledge site */}
          {/* <NavLink
            href="https://anheuserbuschinbev.sharepoint.com/sites/OPRSite0"
            icon={<AiOutlineQuestionCircle />}
            title={t('menu.knowledge_center')}
            collapsed={isCollapsed}
            target="_blank"
            rel="noopener noreferrer"
          /> */}
          {/* <NavLink
              href="#!"
              icon={<MdLanguage />}
              title={t('menu.language_selector')}
              collapsed={isCollapsed}
            /> */}

          <Image
            src={peopleTechLogo}
            alt="People Tech"
            placeholder="blur"
            style={{ margin: '0 auto' }}
          />
        </StyledNavFooter>
      </StyledNav>
    </>
  );
});

NavContent.displayName = 'NavContent';
